module.exports = function mainBackground(elem) {
    const img = new Image();
    img.src = "./assets/images/main-bg.jpg";
    img.onload = () => {
        document
            .querySelector(".main-preview")
            .setAttribute("style", `background-image: url(${img.src});`);
        document.querySelector(".main-preview").classList.add("loaded");
    };

};